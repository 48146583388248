import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { EntityService } from '../../../core/services/api/entity/entity.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from '../../../core/models/user/user.model';
import { UserService } from '../../../core/services/api/user/user.service';
import { AuthService } from '../../../core/services/api/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { catchError, combineLatest, filter, of, Subscription } from 'rxjs';
import { AttributeService } from '../../../core/services/api/attribute/attribute.service';
import { FapModalComponent } from '../../../shared/partials';
import { TypesService } from '../../../core/services/api/types/types.service';
import { WidgetsService } from '../../../core/services/api/widgets/widgets.service';
import { MapService } from '../../../shared/layout/fap_main-map/service/map-service';

@Component({
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss'],
})
export class EntitiesComponent implements OnInit, OnDestroy {
  @ViewChild('configModal') configModal: FapModalComponent;
  @ViewChild('deviceModal') deviceModal: FapModalComponent;
  @ViewChild('addTypeModal') public addTypeModal: FapModalComponent;
  @ViewChild('addEditProfileModal') public addEditProfileModal: FapModalComponent;
  @ViewChild('fieldsModal') public fieldsModal: FapModalComponent;
  @ViewChild('entityFormModal') public entityFormModal: FapModalComponent;
  @ViewChild('addEditTranslationsModal') public addEditTranslationsModal: FapModalComponent;
  @ViewChild('addEditProfileTranslationPopup') public addEditProfileTranslationPopup: FapModalComponent;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('nameInput2', { static: true }) nameInput2: ElementRef;
  @ViewChild('mys') mys;

  @Output()
  public setTriggerSensorGroupTypes: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public currentView: EventEmitter<any> = new EventEmitter<any>();

  public isLoading: boolean = false;
  public entityData: any[] = [];
  public searchString: string = '';
  public EntityNextToken: any;
  public getMoreData: boolean = true;
  public itemList: any[] = [];
  public numberArray: number[];
  public entityColumns: any[] = [];
  public allEntityColumns: any[] = [];
  public allEntityColumnsMap: any;
  public filteredColumns: any[] = [];
  public profileId;
  public tableHeaderLabels: any[] = [];
  public type: string = 'entities';
  public profileForm: FormGroup;
  public user: UserModel;
  public entityTypes: any[] = [];
  public entityTypesMap: any;
  public count: number = 0;
  public limit = 50;
  public pageNumber: number = 1;
  public newEntityType: number;
  public triggerGetFormTypes: boolean = false;
  public clearInput: boolean = false;
  public isNewType: boolean = false;
  public entityType;
  public profile;
  public fields: any[] = [];
  public filteredFields: any[] = [];
  public profiles: any[] =[];
  public selectedFields: any[] = [];
  public selectedFieldsIds: any[] = [];
  public draggedItem: any = null;
  public subscriptions: Array<Subscription> = [];
  public closeProfileCombo: boolean = false;
  public closeTypeCombo: boolean = false;
  public form: any;
  public formType: any;
  public formTypeId: number;
  public langString: string;
  public translation = null;
  public label:string;
  public entityTypeForm: FormGroup;
  public addEditProfileForm: FormGroup;
  public formTypes: any[] = [];

  constructor(
    private cacheService: CacheResolverService,
    public globalRegistry: GlobalRegistryService,
    public entityService: EntityService,
    private toastrService: ToastrService,
    public userService: UserService,
    private typeService: TypesService,
    private authService: AuthService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private attributeService: AttributeService,
    private widgetService: WidgetsService,
    private mapService: MapService
  ) {
    const localQueryParams = localStorage.getItem("queryParams")
  ? JSON.parse(localStorage.getItem("queryParams") as string)
  : {};
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: localQueryParams,
          });
          this.subscriptions.push(
            combineLatest([
              this.activatedRoute.queryParams,
            ]).subscribe(([queryParams]) => {
              if (queryParams['filter'] !== undefined) {
                this.isLoading = true;
                const search = (queryParams['filter'] as string).toLowerCase();
                this.searchString = search
                this.getEntities();
              }
            }))
  }

  ngOnInit(): void {
    this.widgetService.setMapResize(false);
    this.widgetService.setSize(12);
    this.mapService.hideMap();
    this.authService.getConfig().subscribe(data => {
      this.profileId = data.body.results.entity_view.selected_column ? data.body.results.entity_view.selected_column : '';
      if(this.profileId) {
        this.getEntityDataColumns(this.profileId);
      }
    });
    this.profileForm = this.fb.group({
      sensorIds: [[], Validators.required],
      sensorGroup: [this.profileId, Validators.required]
    });
    this.getAllEntityDataColumns();
    this.getEntityTypes();
    this.getProfiles();
    this.getEntityFields();
    this.getAllFormTypes();
    this.initEntityTypeForm();
    this.initProfileForm();
    this.langString = localStorage.getItem('language') ?? 'en';
  }

  public getAllFormTypes() {
    const url = this.typeService.getUrl('form/');
    this.cacheService.delete(url);
    this.typeService.getFormTypes().subscribe((data) => {
        this.formTypes = data.body.results;
        console.log(data.body.results);
    });
  }

  getProfiles() {
    this.entityService.getProfiles().subscribe(data => {
      this.profiles = data.body.results;
    })
  }

  getEntityFields() {
    this.entityService.getEntityFields({type: this.type}).subscribe(data => {
      this.fields = data.body.results;
      this.filteredFields = this.fields;
    })
  }

  public getEntityTypes() {
    this.typeService.getEntityTypes().subscribe((data) => {
      this.entityTypes = data.body.results;
      this.entityTypesMap = new Map(this.entityTypes.map(item => [item.id, item]));
      console.log(data.body.results);
    });
  }

  public getEntities(type?:any) {
    this.isLoading = true;
    this.entityData = [];
    const params = {
      limit: this.limit,
      page: this.pageNumber,
      search: this.searchString,
      profile: this.profileId ? this.profileId : '',
      links_children: true
    };
    const url = this.entityService.getUrl('adv_data/');
    if (this.searchString) {
      Object.assign(params, { search: this.searchString });
    }
    if(type) Object.assign(params, { type: type });
    this.entityService.getEntities(params).subscribe((data) => {
      this.entityData = data.body.results;
      console.log(data.body.results);
      this.isLoading = false;
      this.cacheService.delete(
        url +
          'limit='+this.limit+'&page='+this.pageNumber+'&search=' +
          params.search +
          '&profile=' +
          params.profile + '&links_children=' + true + (type? '&type=' + type : '')
      );
    });
  }

  getAllEntityDataColumns() {
    let url = this.entityService.getUrl('adv_data/profile/');
    this.entityService
      .getEntitiesColumns()
      .subscribe((data) => {
        this.cacheService.delete(url);
        this.allEntityColumns = data.body.results;
        this.allEntityColumnsMap = new Map(this.allEntityColumns.map(item => [item.field_type.id, item.field_type]));
        console.log(this.allEntityColumns);
      });
  }

  getEntityDataColumns(id = '') {
    id = id ? id : '';
    this.itemList = [];
    const url = this.entityService.getUrl('adv_data/profile/');
    this.entityService
      .getEntitiesColumns({ id, type: this.type })
      .subscribe((data) => {
        this.cacheService.delete(
          url + 'id=' + id + '&type=' + this.type
        );
        this.entityColumns = data.body.results;
        this.selectedFields = [];
        this.selectedFieldsIds = [];
        if(data.body.results.length) {
          let newId = data.body.results[0].group;
        // let newId = data.body.results[0].group;
          if(!this.profileId) this.profileId = newId;
          this.getEntities();
          this.profileForm.get('sensorGroup')?.setValue(newId);
          
          this.entityColumns.forEach((column) => {
            this.itemList.push({ field_type: column.field_type.id });
            this.selectedFields.push(column.field_type);
            this.selectedFieldsIds.push(column.field_type.id);
            this.allEntityColumnsMap = new Map(this.entityColumns.map(item => [item.field_type.id, item.field_type]));
          });
        } else {
          this.getEntities();
        }
      });
  }

  showConfigModal() {
    this.configModal.showModal();
  }

  showModal() {
    this.deviceModal.showModal();
  }

  reroute() {
    this.setTriggerSensorGroupTypes.emit(false);
  }

  public removeItem(field_type_id: number): void {
    this.itemList = this.itemList.filter(
      (item) => item.field_type !== field_type_id
    );
    this.selectedFields = this.selectedFields.filter(
      (column) => column?.id !== field_type_id
    );
    this.selectedFieldsIds = this.selectedFieldsIds.filter(
      (column) => column !== field_type_id
    );
    console.log(this.itemList);
    console.log(this.selectedFields);
    console.log(this.selectedFieldsIds);
  }

  addColumns() {
    console.log(this.profileForm.value);
    this.profileId = this.profileForm.get('sensorGroup')?.value;
    this.entityService
      .postColumns(
        this.itemList,
        this.profileId ? this.profileId : ''
      )
      .subscribe((data) => {
        if (data) {
          this.toastrService.success(this.translate.instant('sensor.headerUpdatedSuccessfully'));
          console.log(data);
          this.configModal.hideModal();
          this.entityColumns = data.body.results;
          localStorage.setItem('currentSensorGroup', this.profileId);
          this.changeGroup();
          this.currentView.emit(this.profileId);
        }
      });
  }

  onOptionSelected(event) {
    const ref = { field_type: event };

    if (!this.isDuplicateItem(ref, this.itemList)) {
      this.itemList.push(ref);
    }
    console.log(ref);
    console.log(this.itemList);

    const selectedColumn = this.allEntityColumns.find(
      (column) => column?.field_type?.id === ref.field_type
    );

    if (
      selectedColumn &&
      !this.isDuplicateColumn(selectedColumn, this.entityColumns)
    ) {
      this.entityColumns.push(selectedColumn);
    }
  }

  isDuplicateItem(newItem, itemList) {
    return itemList.some(
      (existingItem) => existingItem.field_type === newItem.field_type
    );
  }

  isDuplicateColumn(newColumn, deviceColumns) {
    return deviceColumns.some(
      (existingColumn) =>
        existingColumn?.field_type?.id === newColumn?.field_type?.id
    );
  }

  selectAllItems() {
    // this.entityColumns = this.allEntityColumns;
    this.fields.forEach(column => {
      this.itemList.push({field_type: column.id});
    });
  }
  
  removeAllItems() {
    // this.fields = [];
    this.itemList = [];
  }

  changeGroup() {
    this.getEntityDataColumns(this.profileId);
    this.profileForm.get('sensorGroup')?.setValue(this.profileId);
    // this.userService.updateUser(body)
    let params = {entity_view: {
        selected_column: this.profileId
      }
    }
    this.authService.setConfig(params).subscribe();
  }

  filterProfiles(id) {
    console.log(id);
  }

  public handlePagination(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.getEntities();
  }

  addEntityType() {
    this.entityType = null;
    this.clearInput = true;
    this.isNewType = true;
    this.closeTypeCombo = true;
    this.triggerGetFormTypes = true;
    this.initEntityTypeForm();
    this.entityTypeForm.clearValidators();
    this.addTypeModal.showModal();
  }

  editEntityType(e) {
    console.log(e);
    this.entityType = e;
    this.clearInput = true;
    this.isNewType = false;
    this.closeTypeCombo = true;
    this.triggerGetFormTypes = true;
    this.initEntityTypeForm();
    this.entityTypeForm.clearValidators();
    this.addTypeModal.showModal();
  }

  addProfile() {
    this.profile = null;
    this.profileId = null;
    this.clearInput = true;
    this.isNewType = true;
    this.triggerGetFormTypes = true;
    this.closeProfileCombo = false;
    this.initProfileForm();
    this.entityTypeForm.clearValidators();
    this.addEditProfileModal.showModal();
  }

  editProfile(e) {
    console.log(e);
    this.profile = e;
    this.clearInput = true;
    this.isNewType = false;
    this.closeProfileCombo = true;
    this.triggerGetFormTypes = true;
    this.initProfileForm();
    this.entityTypeForm.clearValidators();
    this.addEditProfileModal.showModal();
  }

  deleteEntityType(e) {
    this.typeService.deleteEntityType(e.id).subscribe(() => {
      this.entityTypes = this.entityTypes.filter(type => type.id !== e.id);
      this.toastrService.success(this.translate.instant('sensor.typeDeletedSuccessfully'))
    }, (err) => {
      this.toastrService.error(err.error.results.error);
    })
  }

  deleteProfile(e) {
    this.closeProfileCombo = true;
    this.entityService.deleteProfile(e.id).subscribe(() => {
      this.profiles = this.profiles.filter(profile => profile.id !== e.id);
      this.toastrService.success(this.translate.instant('sensor.profileDeletedSuccessfully'))
    }, (err) => {
      this.toastrService.error(err.error.results.error);
    })
  }

  updateForm() {
    let e = this.entityTypeForm.value;
    console.log(e);
    if(this.entityType) {
      this.updateEntityType(e);
    } else {
      this.createEntityType(e);
    }
  }

  updateEntityType(formValue) {
    this.typeService.updateEntityType(this.entityType.id, formValue).subscribe(data => {
      console.log(data);
      this.toastrService.success(this.translate.instant('sensor.typeUpdatedSuccessfully'));
      this.addTypeModal.hideModal();
      this.triggerGetFormTypes = false;
      this.closeTypeCombo = true;
      const index = this.entityTypes.findIndex(product => product.id === data.body.results.id);
      if (index !== -1) {
        this.entityTypes[index] = data.body.results;
        this.entityType = this.entityTypes[index];
        this.newEntityType = this.entityTypes[index].id;
      }
    }, (err) => {
      this.toastrService.error(err.error.results.error);
    })
  }

  createEntityType(formValue) {
    this.typeService.addEntityType(formValue).subscribe(data => {
      console.log(data);
      this.toastrService.success(this.translate.instant('sensor.typeAddedSuccessfully'));
      this.addTypeModal.hideModal();
      this.triggerGetFormTypes = false;
      this.closeTypeCombo = true;
      this.entityTypes.unshift(data.body.results);
    }, (err) => {
      this.toastrService.error(err.error.results.error);
    })
  }

  public sensorGroupTypeSubmit(){
    if(this.addEditProfileForm.invalid) {
        Object.keys(this.addEditProfileForm.controls).forEach((controlName: string) =>
            this.addEditProfileForm.controls[controlName].markAsTouched()
        );
        return;
    }
    let formValue = this.addEditProfileForm.value;
    if(this.profileId){
        this.editSensorGroupType(formValue, this.profileId);
        this.addEditProfileModal.hideModal();
        
    } else {
        this.addSensorGroupType(formValue)
        this.addEditProfileModal.hideModal();
        
    }
  }

  public addSensorGroupType(sensorGroup): void {
    this.entityService.addProfile(sensorGroup, this.type).subscribe((data) => {
      if(data) {
        console.log(data);
        this.profile = data.body.results;
        this.profileId = this.profile.id;
        this.profiles.push(this.profile);
        this.profileForm.get('sensorGroup')?.setValue(this.profileId);
        this.closeProfileCombo = true;
        this.changeGroup();
      }
        this.toastrService.success(this.translate.instant('sensor.profileAddedSuccessfully'));
        this.getProfiles();
    });    
}

public editSensorGroupType(sensorGroup, profileId: number): void {
        this.entityService.updateProfile(profileId, sensorGroup, this.type).subscribe((data) => {
        if(data) {
          this.profile = data.body.results;
          this.profileId = this.profile.id;
          const index = this.profiles.findIndex(type => type.id === this.profileId);
          if (index !== -1) {
            this.profiles[index] = data.body.results;
            console.log(this.profiles);
          }
          this.profileForm.get('sensorGroup')?.setValue(this.profileId);
          this.closeProfileCombo = true;
          this.changeGroup()
        }
        this.toastrService.success(this.translate.instant('sensor.profileUpdatedSuccessfully'));
        this.getProfiles();
    });    
}

  public showSensorGroupTypeModal(sensorGroup?:any): void {
      if(sensorGroup) {
          this.profile = sensorGroup
          this.profileId = sensorGroup.id;
      } else {
        this.profile = null
        this.profileId = null;
      }
      this.closeProfileCombo = false;
      this.addEditProfileModal.showModal();
  }

  onDragStart(item: any) {
    this.draggedItem = item;
  }

  onDragEnd() {
    this.draggedItem = null;
  }

  // Handle the drop event to move the item within itemList
  dropItem(previousIndex: number, currentIndex: number) {
    if (previousIndex !== currentIndex) {
      const item = this.itemList.splice(previousIndex, 1)[0];
      this.itemList.splice(currentIndex, 0, item);
    }
  }

  onItemDrop(targetItem: any) {
    if (this.draggedItem) {
      const previousIndex = this.itemList.indexOf(this.draggedItem);
      const targetIndex = this.itemList.indexOf(targetItem);

      // Move item within the list
      this.dropItem(previousIndex, targetIndex);
      this.draggedItem = null;
    }
  }

  public openFieldsModal() {
    this.fieldsModal.showModal();
  }

  public collectValues(valueArray) {
    if(valueArray.length === 0) {
      this.selectedFields = [];
      this.itemList = [];
      this.selectedFieldsIds = [];
      return
    }
    this.selectedFields = [];
    this.itemList = [];
    this.selectedFieldsIds = valueArray;
    valueArray.forEach(value => {
      const field = this.fields.find(element => element.id === value);
        if (field) {
          this.selectedFields.push(field);
          this.itemList.push({ field_type: value });
        }
    });
  }

  public editForm(item) {
    console.log(item);
    this.getForm(item.form);
  }

  public getForm(formId: number) {
    let url = this.attributeService.getUrl('form/' + formId);
    this.cacheService.delete(url);
    this.attributeService.getForm(formId).subscribe({
      next: (response) => {
        this.form = response.body.results;
        this.formTypeId = response.body.results.type;
        this.getFormTypes();
      },
      error: (error) => {
        this.toastrService.error(error.error.results.error);
      }
    }); 
  }

  public getFormTypes() {
    if(!this.formTypeId) return
    this.typeService.getFormTypeById(this.formTypeId).subscribe((data) => {
      console.log(data);
        this.formType = data.body.results;
        this.entityFormModal.showModal();
    });
}

  public filterEntities(e) {
    this.filteredFields = this.fields.filter(field => 
      Object.values(field).some(value => 
        value?.toString().toLowerCase().includes(e.toString().toLowerCase())
      )
    );    
  }

  public filterEntityData(event) {
    this.entityTypes.forEach(entityType => {
      if(entityType.id === event.value) {
        this.entityType = entityType
      }
    });
    this.getEntities(event.value);
  }

  hideModal() {
    this.entityFormModal.hideModal();
  }

  onUpdateAction(e) {
    console.log(e);
    this.getEntities();
  }

  addNewEntity() {
    if(!this.entityType) {
      this.toastrService.error('Please select a type!')
      return
    }
    console.log(this.entityType);
    let params = {type: this.entityType.entity_form, object_id: 0}
    this.attributeService.getAttributeForm(params).subscribe((data: any) => {
      this.form = data.body.results[0];
      this.formTypeId = data.body.results[0].type;
      this.toastrService.success('Form created successfully');
      this.getFormTypes();
    }, (err: any) => {
      this.toastrService.error(err.error.results.error);
    })
  }

  initEntityTypeForm() {
    this.entityTypeForm = new FormGroup({
      name: new FormControl(this.entityType ? this.entityType.name : '', Validators.required),
      name_t: new FormControl(this.entityType ? this.entityType.name_t : ''),
      entity_form: new FormControl(this.entityType ? this.entityType.entity_form : null),
      uniqueid: new FormControl(this.entityType ? this.entityType.uniqueid : null),
      color: new FormControl(this.entityType ? this.entityType.color : '#ff0000'),
    });
  }

  initProfileForm() {
    this.addEditProfileForm = new FormGroup({
      name: new FormControl(this.profile ? this.profile.name : '', Validators.required),
      name_t: new FormControl(this.profile ? this.profile.name_t : ''),
      entity_form: new FormControl(this.profile ? this.profile.entity_form : null),
      uniqueid: new FormControl(this.profile ? this.profile.uniqueid : null),
      color: new FormControl(this.profile ? this.profile.color : '#ff0000'),
    });
  }

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
      const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
      camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
    }
    return camelCaseObj;
  }

  setFormType(formType) {
    this.entityTypeForm.get('entity_form').setValue(formType.id);
  }

  addEditTranslation(label: string, inputElement: any, translationTarget: any, popup: any, form: any) {
    this.label = label;
    const name = this[inputElement].nativeElement.value;
    const type: any = this.convertKeysToCamelCase(translationTarget);

    if (name === null || name === '' || type.nameT === null) {
        this.translation = null;
        popup.showModal();
    } else {
        for (const translation of this.globalRegistry.systemData.translations) {
            if (type && translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                popup.showModal();
                return;
            }
        }

        if (Object.keys(type).length === 0 && name) {
            const newTranslation = this[form].controls['name_t'].value;
            for (const translation of this.globalRegistry.systemData.translations) {
                if (translation.id === newTranslation) {
                    this.translation = translation;
                    console.log(this.translation);
                    popup.showModal();
                    return;
                }
            }
        }
    }
}  

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.entityTypeForm.controls['name_t'].setValue(translation.id);
    this.entityTypeForm.controls['name'].setValue(translation[this.langString]);
    this.addEditTranslationsModal.hideModal();
  }

  onAddUpdateProfileTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.addEditProfileForm.controls['name_t'].setValue(translation.id);
    this.addEditProfileForm.controls['name'].setValue(translation[this.langString]);
    this.addEditProfileTranslationPopup.hideModal();
}

  ngOnDestroy(): void {
    this.widgetService.setMapResize(true);
    this.widgetService.setSize(6);
    this.mapService.showMap();
  }

}
